import Container from '@thebeansgroup/ui_grids/container'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Body from '@thebeansgroup/ui_typography/body'
import Title1 from '@thebeansgroup/ui_typography/title1'
import Title3 from '@thebeansgroup/ui_typography/title3'

import CyclingText from '@components/cycling_text'
import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

import {
  AVATARS_TEXT,
  DISCOUNTS_TO_HELP_YOU_SAVE,
  PROUD_TO_SUPPORT,
  WORDS_TO_CYCLE
} from './constants'
import * as styles from './styles'

const HomePageHero = () => {
  const renderTitle = () => (
    <div css={styles.title}>
      <Title1
        component='h1'
        colour={Colours('white', 300)}
      >
        {DISCOUNTS_TO_HELP_YOU_SAVE}
      </Title1>
    </div>
  )

  const renderSubtitle = () => (
    <div css={styles.subtitle}>
      <Title3
        component='h2'
        colour={Colours('white', 300)}
      >
        {PROUD_TO_SUPPORT}
        <br css={styles.hiddenOnDesktop} />
        <CyclingText wordsToCycle={WORDS_TO_CYCLE} />
      </Title3>
    </div>
  )

  const renderAvatars = () => (
    <div css={styles.userProfilesRow}>
      <SafeImage
        src={withAssetPrefix('images/home_page_hero_avatars.png')}
        alt='User profile images'
        width={150}
        height={70}
        css={styles.userProfiles}
      />
      <Body colour={Colours('white')}>{AVATARS_TEXT}</Body>
    </div>
  )
  return (
    <section css={styles.container}>
      <Container>
        {renderTitle()}
        {renderSubtitle()}
        {renderAvatars()}
      </Container>
    </section>
  )
}

export default HomePageHero
