import { gql } from '@apollo/client'
import PropTypes from 'prop-types'

import AlgoliaPageOfferList from '@components/algolia_page_offer_list'
import Faq from '@components/faq'
import HomePageHero from '@components/home_page_hero'
import HomePageHowItWorks from '@components/home_page_how_it_works'
import HomePageMetadata from '@components/home_page_metadata'
import Page from '@components/page'
import {
  FAQS,
  FAQ_TITLE,
  DEFAULT_FILTERS,
  DEFAULT_SORT
} from '@constants/home_page'
import { getAlgoliaResults } from '@helpers/algolia'
import { setCloudFrontCacheExpiryInHours } from '@helpers/cache'
import { getCategoryFromParams } from '@helpers/categories'
import { isValidCountry } from '@helpers/countries'
import { handleServerSideError } from '@helpers/errors'

const HOME_USER_QUERY = gql`
  query HomeUserDataQuery {
    accountsViewer {
      id
      ...PageViewer
    }
  }
  ${Page.fragments.viewer}
`

const HomePage = ({ offers }) => (
  <Page
    pageLabel='Home'
    fullWidth
    smoothScroll
    query={HOME_USER_QUERY}
    transparentHeader
  >
    <HomePageMetadata />
    <HomePageHero />
    <AlgoliaPageOfferList
      offers={offers}
      isHomePage
    />
    <HomePageHowItWorks />
    <Faq
      faqs={FAQS}
      title={FAQ_TITLE}
    />
  </Page>
)

export async function getServerSideProps(context) {
  const countryCode = context?.params?.country
  const category = getCategoryFromParams(context?.params)

  if (!isValidCountry(countryCode)) {
    return {
      notFound: true
    }
  }

  try {
    const offers = await getAlgoliaResults(
      0,
      DEFAULT_FILTERS,
      countryCode,
      category,
      DEFAULT_SORT
    )

    setCloudFrontCacheExpiryInHours(context, 1)

    return {
      props: {
        offers
      }
    }
  } catch (error) {
    return handleServerSideError(error, context)
  }
}

HomePage.propTypes = {
  offers: PropTypes.object
}

HomePage.defaultProps = {
  offers: {}
}

export default HomePage
