import PropTypes from 'prop-types'

import * as styles from './styles'

const CyclingText = ({ wordsToCycle, cycleTime }) => {
  const renderWords = () =>
    wordsToCycle.map((word, index) => {
      const DELAY = index * cycleTime
      const DURATION = wordsToCycle.length * cycleTime
      return (
        <span
          css={styles.word(DELAY, DURATION)}
          key={`cycle-text-${index}`}
        >
          {word}
        </span>
      )
    })

  return <span css={styles.container}>{renderWords()}</span>
}

CyclingText.propTypes = {
  wordsToCycle: PropTypes.arrayOf(PropTypes.string),
  cycleTime: PropTypes.number
}

CyclingText.defaultProps = {
  wordsToCycle: [],
  cycleTime: 3
}

export default CyclingText
