import { css, keyframes } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const container = css`
  display: inline;
`

export const topToBottom = keyframes`
  0% {
    opacity: 0;
  }
  6% {
    opacity: 0;
    transform: translateY(-35px);
  }
  8% {
    opacity: 1;
    transform: translateY(0px);
  }
  22% {
    opacity: 1;
    transform: translateY(0px);
  }
  24% {
    opacity: 0;
    transform: translateY(35px);
  }
  100% {
    opacity: 0;
  }
`
export const word = (animationDelay, duration) => css`
  animation: ${topToBottom} ${duration}s ease-in-out infinite 0s;
  animation-delay: ${animationDelay}s;
  color: ${Colours('brightBlue')};
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  margin-left: -150px;
  width: 300px;

  ${desktop} {
    left: unset;
    margin-left: 0;
    width: unset;
    text-indent: 6px;
  }
`
