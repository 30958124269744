import PropTypes from 'prop-types'
import { useState } from 'react'

import Column from '@thebeansgroup/ui_grids/column'
import Uber1 from '@thebeansgroup/ui_typography/uber1'

import BeansIdButton from '@components/beans_id_button'
import Container from '@components/container'
import FaqItem from '@components/faq_item'

import { FAQS_TO_LOAD } from './constants'
import { getFaqs, getFaqButtonText } from './helpers'
import * as styles from './styles'

const Faq = ({ faqs, title }) => {
  const [showMoreFaqs, setShowMoreFaqs] = useState(false)

  const renderFaqs = () => {
    const displayedFaqs = getFaqs(showMoreFaqs, faqs)

    return displayedFaqs.map((faq, i) => (
      <FaqItem
        key={`faq-${i}`}
        question={faq.question}
        answer={faq.answer}
        regionId={`region-${i}`}
        accordionId={`faq-item-${i}`}
      />
    ))
  }

  const renderShowMoreButton = () => {
    if (faqs.length <= FAQS_TO_LOAD) return null

    return (
      <BeansIdButton
        element='button'
        onClick={() => setShowMoreFaqs(!showMoreFaqs)}
        colour='brightBlue'
      >
        {getFaqButtonText(showMoreFaqs)}
      </BeansIdButton>
    )
  }

  const renderTitle = () => (
    <div
      css={styles.title}
      data-testid='faqs'
      id='help'
    >
      <Uber1 component='h2'>{title}</Uber1>
    </div>
  )

  return (
    <Container>
      <div css={styles.faqContainer}>
        <Column
          mother={12}
          brother={12}
          sister={12}
          baby={12}
          center
        >
          {renderTitle()}
          <div css={styles.container}>{renderFaqs()}</div>
        </Column>
        {renderShowMoreButton()}
      </div>
    </Container>
  )
}

Faq.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired
    })
  ),
  title: PropTypes.string.isRequired
}

Faq.defaultProps = {
  faqs: []
}

export default Faq
