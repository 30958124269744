import { css } from '@emotion/react'

import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const container = css`
  position: relative;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -70px;
  padding: 105px 20px 35px;
  width: auto;
  text-align: center;
  background: linear-gradient(
    to bottom right,
    hsl(240, 100%, 11.96%) 0%,
    hsl(240, 100%, 12.35%) 9.7%,
    hsl(240, 97.1%, 13.53%) 18.2%,
    hsl(240, 94.87%, 15.29%) 25.6%,
    hsl(240, 91.11%, 17.65%) 32.1%,
    hsl(240, 86.54%, 20.39%) 37.9%,
    hsl(240, 81.51%, 23.33%) 43.3%,
    hsl(240, 76.3%, 26.47%) 48.3%,
    hsl(240, 72.19%, 29.61%) 53.2%,
    hsl(240, 66.47%, 32.75%) 58.2%,
    hsl(240, 62.64%, 35.69%) 63.4%,
    hsl(240, 58.16%, 38.43%) 69.1%,
    hsl(240, 54.59%, 40.59%) 75.4%,
    hsl(240, 51.85%, 42.35%) 82.5%,
    hsl(240, 49.55%, 43.53%) 90.7%,
    hsl(240, 49.11%, 43.92%) 100%
  );

  h1 {
    font-size: 42px;
  }
  p {
    margin: 15px 0;
  }

  ${desktop} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 50px;

    h1 {
      font-size: 54px;
    }
  }
`

export const title = css`
  margin: 0 auto;
`

export const subtitle = css`
  margin-top: 24px;
  text-align: center;

  h2 {
    font-size: 24px;

    ${desktop} {
      font-size: 32px;
      margin-left: -130px;
    }
  }
`

export const userProfilesRow = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 40px;

  ${desktop} {
    white-space: nowrap;
    flex-direction: row;
    text-align: left;
    margin-top: 20px;
  }
`

export const userProfiles = css`
  object-fit: contain;
  margin-right: 10px;
  margin-bottom: -15px;

  ${desktop} {
    margin-bottom: 0;
  }
`

export const hiddenOnDesktop = css`
  ${desktop} {
    display: none;
  }
`
