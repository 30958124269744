import PropTypes from 'prop-types'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Body from '@thebeansgroup/ui_typography/body'
import Title3 from '@thebeansgroup/ui_typography/title3'

import SafeImage from '@components/safe_image'
import { withAssetPrefix } from '@helpers/assets'

import * as styles from './styles'

const HomePageHowItWorksItem = ({ icon, title, description }) => {
  return (
    <div css={styles.container}>
      <SafeImage
        src={withAssetPrefix(`images/how_it_works/${icon}.svg`)}
        alt={title}
        width={50}
        height={50}
      />
      <Title3
        component='h3'
        colour={Colours('grey', 500)}
      >
        {title}
      </Title3>
      <Body colour={Colours('grey', 500)}>{description}</Body>
    </div>
  )
}

HomePageHowItWorksItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

export default HomePageHowItWorksItem
