import Metadata from '@components/metadata'
import { useCountryCode } from '@hooks/use_country_code'

import { DESCRIPTION, LD_DATA } from './constants'
import { getTitle, getCanonical } from './helpers'

const HomePageMetadata = () => {
  const country = useCountryCode()

  return (
    <Metadata
      title={getTitle()}
      description={DESCRIPTION}
      canonicalUrl={getCanonical(country)}
      ldData={LD_DATA}
    />
  )
}

export default HomePageMetadata
