import { getNextRoute } from '@helpers/routes'

export const getYear = () => new Date().getFullYear()

export const getTitle = (t) => {
  const year = getYear()
  return `BeansiD | Valid ${year} Discount Codes`
}

export const getCanonical = (country) => {
  const route = getNextRoute('home', { country })
  return `https://www.beansid.com${route}`
}
