import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const howItWorksContainer = css`
  width: 100%;
  text-align: center;
  background-color: ${Colours('grey', '100')};
  padding: 20px;
  margin-top: 40px;

  h2 {
    font-size: 42px;
    margin: 20px 0;
  }

  ${desktop} {
    padding: 40px 0;
    margin-top: 60px;

    h2 {
      font-size: 54px;
      margin: 15px 0 30px;
    }
  }
`

export const contentContainer = css`
  max-width: 1450px;
  margin: 0 auto;
`
