export const HOW_IT_WORKS_TITLE = 'How it works'

export const HOW_IT_WORKS_INSTRUCTIONS = [
  {
    icon: 'time',
    title: 'Create an account',
    description:
      'Start by creating an account it only takes 3 mins. All you need is a personal email to link to your account'
  },
  {
    icon: 'verify',
    title: 'Verify your identity',
    description:
      'For this you will need an official document like NHS card OR your work email'
  },
  {
    icon: 'trophy',
    title: 'Start saving',
    description:
      'Enjoy stretching your budget further and save on over 10,000 brands.'
  }
]
