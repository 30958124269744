import { FAQS_TO_LOAD } from './constants'

export const getFaqs = (showMoreFaqs, faqs) => {
  if (showMoreFaqs) return faqs

  return faqs.slice(0, FAQS_TO_LOAD)
}

export const getFaqButtonText = (showMoreFaqs) => {
  if (showMoreFaqs) return 'Show less'

  return 'See all FAQs'
}
