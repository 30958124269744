import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const itemContainer = css`
  max-width: 1020px;
  border: 2px solid ${Colours('huckleberry', '500')};
  border-radius: 28px;
  margin: 0 auto;
  margin-bottom: 16px;
`

export const itemTitle = css`
  width: 100%;
  padding: 10px 0px 8px;
  cursor: pointer;

  &:focus {
    outline: 1px solid ${Colours('midnight', '500')};
  }
`

export const itemContainerOpen = css`
  display: flex;
  flex-direction: column;
`

export const questionContainer = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  padding: 8px 24px;
`

export const answerContainer = css`
  text-align: left;
  margin: 0 24px;
`

export const closed = css`
  display: none;
`
