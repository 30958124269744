import Column from '@thebeansgroup/ui_grids/column'
import Row from '@thebeansgroup/ui_grids/row'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Title1 from '@thebeansgroup/ui_typography/title1'

import HomePageHowItWorksItem from '@components/home_page_how_it_works_item'

import { HOW_IT_WORKS_INSTRUCTIONS, HOW_IT_WORKS_TITLE } from './constants'
import * as styles from './styles'

const HomePageHowItWorks = () => {
  const renderContent = () => {
    return HOW_IT_WORKS_INSTRUCTIONS.map(
      ({ icon, title, description }, index) => (
        <Column
          key={index}
          mother={3}
          brother={12}
          sister={12}
          baby={12}
          center
        >
          <HomePageHowItWorksItem
            key={index}
            icon={icon}
            title={title}
            description={description}
          />
        </Column>
      )
    )
  }

  return (
    <div
      css={styles.howItWorksContainer}
      id='how-it-works'
    >
      <Title1
        component='h2'
        colour={Colours('grey', 500)}
      >
        {HOW_IT_WORKS_TITLE}
      </Title1>
      <div css={styles.contentContainer}>
        <Row>{renderContent()}</Row>
      </div>
    </div>
  )
}

export default HomePageHowItWorks
