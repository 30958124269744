import { css } from '@emotion/react'

import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const container = css`
  padding-top: 20px;

  ${desktop} {
    h3 {
      margin: 16px 0;
    }
  }
`
