export const WORDS_TO_CYCLE = [
  'teachers',
  'healthcare workers',
  'parents',
  'first responders',
  'troops',
  'charity workers'
]

export const PROUD_TO_SUPPORT = 'Proud to support'
export const DISCOUNTS_TO_HELP_YOU_SAVE = 'Discounts to help you save'
export const AVATARS_TEXT =
  'Thousands of people are already saving with their BeansiD'
