import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { desktop } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const faqContainer = css`
  width: 100%;
  text-align: center;
`

export const title = css`
  margin: 30px 0;

  ${desktop} {
    padding: 40px 0;
  }
`

export const container = css`
  border-radius: 5px;
  background: ${Colours('white', '300')};
`
