import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'

import UnstyledButton from '@thebeansgroup/ui_ctas/unstyled_button'
import ChevronIconThin from '@thebeansgroup/ui_icons/chevron_icon_thin'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Body from '@thebeansgroup/ui_typography/body'
import Callout from '@thebeansgroup/ui_typography/callout'

import { createMarkup } from '@helpers/markdown'

import { getIconLabel } from './helpers'
import * as styles from './styles'

const FaqItem = ({
  question,
  answer,
  regionId,
  accordionId,
  questionColour,
  questionWeight
}) => {
  const [open, setOpen] = useState(false)

  const renderQuestion = () => (
    <div css={styles.questionContainer}>
      <Body
        fontWeight={questionWeight}
        colour={questionColour}
      >
        {question}
      </Body>
      <ChevronIconThin
        rotateDegrees={open ? 180 : 0}
        label={getIconLabel(open)}
      />
    </div>
  )

  const renderAnswer = () => (
    <div
      id={regionId}
      css={[styles.answerContainer, !open && styles.closed]}
      role='region'
      aria-labelledby={accordionId}
      data-testid='faq-answer'
    >
      <Callout
        colour={Colours('grey', '500')}
        component={'div'}
      >
        <div dangerouslySetInnerHTML={createMarkup(answer)} />
      </Callout>
    </div>
  )

  return (
    <Fragment>
      <UnstyledButton
        css={[
          styles.itemTitle,
          styles.itemContainer,
          open && styles.itemContainerOpen
        ]}
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        aria-controls={regionId}
        id={accordionId}
        data-testid={accordionId}
      >
        {renderQuestion()}
        {renderAnswer()}
      </UnstyledButton>
    </Fragment>
  )
}

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  regionId: PropTypes.string.isRequired,
  accordionId: PropTypes.string.isRequired,
  questionColour: PropTypes.string,
  questionWeight: PropTypes.oneOf(['normal', 'semibold', 'bold'])
}

FaqItem.defaultProps = {
  questionColour: Colours('grey', '500'),
  questionWeight: 'semibold'
}

export default FaqItem
